<template>
    <v-dialog ref="dialog" v-model="modal" :return-value.sync="dateRangeText" persistent width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field v-model="dateRangeText" :label="label" prepend-inner-icon="event" readonly v-on="on" />
        </template>
        <v-date-picker v-model="dates" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">{{ $t("Cancelar") }}</v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(dateRangeText)">OK</v-btn>
        </v-date-picker>
    </v-dialog>
</template>

<script>
import dateTime from "@/helpers/DateTime";

export default {
    name: "DateInterval",

    props: {
        today: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "Intervalo",
        },
        limit: {
            type: Number,
            default: 30,
        },
    },

    data() {
        return {
            dateTime,
            modal: false,
            dates: [],
        };
    },

    created() {
        if (this.today) {
            this.dates[0] = this.$moment().subtract({ hours: 3 }).toISOString().substr(0, 10);
        } else {
            this.dates[0] = this.$moment().subtract({ months: 1, hours: 3 }).toISOString().substr(0, 10);
            this.dates[1] = this.$moment().subtract({ hours: 3, days: 1 }).toISOString().substr(0, 10);
        }
        this.$emit("onChange", this.dates);
    },

    computed: {
        dateRangeText: {
            get: function () {
                let dateFormatted = [];
                this.dates.forEach((date) => {
                    dateFormatted.push(this.dateTime.tranformDate(date));
                });

                return dateFormatted.join(this.$t(" à "));
            },
            set: function () {
                if ((new Date(this.dates[1]).getTime() - new Date(this.dates[0]).getTime()) / (1000 * 3600 * 24) > this.limit) {
                    this.$eventHub.$emit("msgError", this.$t(`Selecione um periodo de tempo menor que ${this.limit} dias.`));
                } else {
                    this.$emit("onChange", this.dates);
                }
            },
        },
    },
};
</script>